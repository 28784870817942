/* Body styling for a full-page background and clean appearance */
body {
  margin: 0;
  padding: 0;
  background: linear-gradient(135deg, #1f1c2c, #928dab);
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden; /* Prevent vertical scroll */
}

/* Main container for the dashboard page */
.dashboardPage {
  width: 100vw;
  max-width: 1400px;
  height: 95vh; /* Adjust height to fit in the viewport */
  overflow-y: auto; /* Enable scrolling within the dashboard */
  background: rgba(255, 255, 255, 0.1);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Page title styling */
h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
  letter-spacing: 1.5px;
  color: #ffffff;
  font-weight: 700;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

/* Chart container */
.chartContainer {
  width: 100%;
  max-width: 1200px;
  background: rgba(255, 255, 255, 0.2);
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Section heading */
h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #ffffff;
  letter-spacing: 1px;
  font-weight: 600;
}

/* Button group container */
.buttonGroup {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
}

/* Buttons with a modern, sleek look */
.buttonGroup button {
  background: linear-gradient(135deg, #ff416c, #ff4b2b);
  color: white;
  border: none;
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s ease;
  box-shadow: 0 6px 15px rgba(255, 75, 43, 0.5);
}

.buttonGroup button:hover {
  background: linear-gradient(135deg, #ff4b2b, #ff416c);
  box-shadow: 0 12px 20px rgba(255, 75, 43, 0.7);
  transform: scale(1.05);
}

/* Error message styling */
.error {
  color: #ff4b2b;
  font-size: 1.5rem;
  text-align: center;
  background: rgba(255, 75, 43, 0.1);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(255, 75, 43, 0.3);
  margin-top: 20px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .dashboardPage {
    padding: 20px;
    height: auto; /* Allow height to expand as needed */
  }

  .chartContainer {
    margin-bottom: 20px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .buttonGroup {
    flex-direction: column;
    width: 100%;
  }

  .buttonGroup button {
    width: 100%;
    padding: 10px 25px;
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  .buttonGroup {
    flex-direction: column;
    width: 100%;
  }

  .buttonGroup button {
    width: 100%;
    padding: 10px 20px;
  }
}
