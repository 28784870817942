/* Fundal */
body {
  background-image: url('truck.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Container general */
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  padding: 40px;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  border: none;
}

/* Titlul paginii */
h1 {
  margin-bottom: 30px;
  font-size: 3em;
  font-weight: bold;
  color: #ff4b2b;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

/* Formular de login */
.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.1);
}

/* Inputurile */
.login-form input {
  margin-bottom: 20px;
  padding: 15px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #ff4b2b;
  background: transparent;
  color: #fff;
  border-radius: 0;
  transition: all 0.3s ease;
}

.login-form input:focus {
  border-bottom-color: #ffffff;
  outline: none;
}

/* Butonul de login */
.login-form button {
  padding: 15px;
  background-color: #ff4b2b;
  color: white;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.login-form button:hover {
  background-color: #d63b21;
  transform: scale(1.05);
}

/* Mesaj de eroare */
.error {
  color: #ff4b2b;
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
}

/* Cookie alert */
.cookie-alert {
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cookie-alert p {
  margin: 0;
  color: #333;
  font-weight: bold;
}

.cookie-alert button {
  background-color: #ff4b2b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

/* Responsivitate */
@media (max-width: 768px) {
  .login-form {
    width: 90%;
  }

  h1 {
    font-size: 2.5em;
  }
}
