/* General Styling */
body {
  background-image: url('truck.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Main container */
.manageAccountsPage {
  width: 95vw;
  max-width: 1200px;
  background: rgba(0, 0, 0, 0.85);
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  height: 95vh;
  color: white;
}

/* Title */
.title {
  font-size: 2.5rem;
  text-align: center;
  color: #ff4b2b;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 700;
}

/* Button Group Styling */
.buttonGroup {
  display: flex;
  justify-content: space-between;
  max-width: 600px;
  margin: 0 auto 20px;
}

.buttonGroup button {
  background-color: #ff4b2b;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.buttonGroup button:hover {
  background-color: #d63b21;
  transform: scale(1.1);
}

/* Client List Styling */
.clientsList {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 60vh;
  overflow-y: auto;
}

/* Individual Client Item */
.clientItem {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.clientItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
}

.active {
  background: linear-gradient(135deg, #00b09b, #96c93d);
}

.inactive {
  background: linear-gradient(135deg, #c31432, #240b36);
}

/* Client Details */
.clientDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 1rem;
}

.clientDetails span {
  color: white;
  font-weight: 600;
}

/* Subscription Actions */
.subscriptionActions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.packageSelect {
  background-color: #fff;
  color: #333;
  border: none;
  padding: 10px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
}

.packageSelect:hover {
  background-color: #f1f1f1;
  transform: scale(1.05);
}

/* Client Actions */
.clientActions {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.clientActions button {
  background-color: #ff4b2b;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.clientActions button:hover {
  background-color: #d63b21;
  transform: scale(1.1);
}

/* Notes Section */
.notesSection {
  margin-top: 20px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.notesSection p {
  color: white;
  font-size: 14px;
  margin-bottom: 5px;
}

/* Modal for Client Edit */
.editClientModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-height: 80vh;
  background: rgba(255, 255, 255, 0.95);
  padding: 30px;
  border-radius: 15px;
  z-index: 1000;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
}

.editClientModal h2 {
  text-align: center;
  color: #ff4b2b;
  margin-bottom: 15px;
  font-size: 1.8rem;
  font-weight: 700;
}

/* Input Fields and Selects in Modal */
.editClientModal input,
.editClientModal select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 1rem;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.editClientModal button {
  width: 100%;
  background-color: #28a745;
  color: white;
  padding: 12px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.editClientModal button:hover {
  background-color: #218838;
  transform: scale(1.1);
}

/* Responsiveness */
@media (max-width: 768px) {
  .editClientModal {
    width: 90%;
    max-height: 70vh;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
  }

  .clientsList {
    max-height: 50vh;
  }

  .buttonGroup {
    flex-direction: column;
    gap: 10px;
  }

  .clientItem {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .clientActions {
    justify-content: center;
    flex-direction: column;
  }
}
